

export const OrderStatus = {
    "0":"审核通过",
    "1":"审批失败",
    "2":"审批中",
    "3":"未审批",
    "4":"待金额到账",
    "5":"金额未到账",
    "6":"未知"
}
