
import Storage from "@/util/Storage"
import {DepositWater} from "@/Api/Money";
import {DepositWaterType} from "@/Api/Money/indexType";

// 查询提现流水
export function getDepositWater(body:DepositWaterType) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(DepositWater({ userId,token },body).then(res=>{
        return res.data
    }))
}
